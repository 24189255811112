import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'
import {For} from 'babel-plugin-jsx-control-statements'
import Appshell from '../components/Appshell'
import Book from '../uikit/Book'
import GatsbyImage from '../components/GatsbyImage'
import Background from '../uikit/Background'
import Flex from '../uikit/Flex'
import Button from '../uikit/Button'


const BookPage = ({data}) => {
  const {frontmatter, ...pageData} = data?.markdownRemark
  const otherBooks = data?.otherBooks?.nodes

  return (
    <Appshell title={frontmatter?.title} seo={frontmatter?.seo}>
      <Background
          desktopBackground={frontmatter?.desktopBackground?.publicURL}
          mobileBackground={frontmatter?.mobileBackground?.publicURL}
      >
        <Flex column>
          <Book>
            <GatsbyImage
                loading="eager"
                image={frontmatter?.image?.childImageSharp?.gatsbyImageData}
                alt={frontmatter?.imageAlt}
            />
          </Book>
          <h1>{frontmatter?.title}</h1>
          <p>{frontmatter?.detailTags}</p>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{__html: pageData?.html}} />
          <Flex mainAxis="center">
            <Button component={Link} isLink to="/subscription">Chcem predplatné!</Button>
          </Flex>
          <Flex>
            <For each="book" of={otherBooks}>
              <Link key={book.id} to={book.frontmatter?.slug}>
                <Book>
                  <GatsbyImage
                      image={book.frontmatter?.image?.childImageSharp?.gatsbyImageData}
                      alt={book.frontmatter?.imageAlt}
                  />
                </Book>
              </Link>
            </For>
          </Flex>
          <Flex mainAxis="center">
            <Button component={Link}isLink to="/">Späť na celý výber</Button>
          </Flex>
        </Flex>
      </Background>
    </Appshell>
  )
}

export const data = graphql`
  query Book($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        detailTags
        desktopBackground {
          publicURL
        }
        mobileBackground {
          publicURL
        }
        image {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              transformOptions: {fit: COVER}
              placeholder: BLURRED
            )
          }
        }
        seo {
          metaTitle
          metaDescription
          metaImage {
            publicURL
          }
        }
      }
    }
    otherBooks: allMarkdownRemark(
      filter: {frontmatter: {template: {eq: "book"}, homepage: {eq: true}}, id: {nin: [$id]}}
    ) {
      nodes {
        id
        frontmatter {
          image {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                transformOptions: {fit: COVER}
                placeholder: BLURRED
              )
            }
          }
          imageAlt
          slug
        }
      }
    }
  }
`

BookPage.propTypes = {
  data: PropTypes.object,
}

export default BookPage
